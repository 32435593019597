import { generateId } from '@freelancer/datastore/testing/helpers';
import { SkillQuestionContextTypeApi } from 'api-typings/common/common';
import type { SkillAnswer } from '../project-view-projects';
import type { Skill, SkillQuestion } from './skills.model';

export function generateSkillObjects(): readonly Skill[] {
  return skills;
}

export function generateLoadTypeObjects(): readonly Skill[] {
  return [
    freightSkill(),
    generalFullLoadSkill(),
    carSkill(),
    haySkill(),
    machineryMobile(),
    palletsSkill(),
    BoatSkill(),
    LivestockSkill(),
  ] as const;
}

const skills = [
  phpSkill(),
  pythonSkill(),
  websiteDesignSkill(),
  translationSkill(),
  photographySkill(),
  logoDesignSkill(),
  dataProcessingSkill(),
  dataEntrySkill(),
  mobileAppDevelopmentSkill(),
  articleWritingSkill(),
  graphicDesignSkill(),
  perlSkill(),
  javaSkill(),
  javaScriptSkill(),
  copywritingSkill(),
  linuxSkill(),
  accountingSkill(),
  adobeFlashSkill(),
  photoshopSkill(),
  androidSkill(),
  deliverySkill(),
  freightSkill(),
  heygenSkill(),
  otherSkill(),
] as const;

/* For converting seoUrls to snake-case */
export function snakeCase(text: string): string {
  return text.toLowerCase().replace(/[\s-]+/, '_');
}

export function phpSkill(): Skill {
  return {
    id: 3,
    name: 'PHP',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'php',
    activeProjectCount: 467,
  };
}

export function perlSkill(): Skill {
  return {
    id: 4,
    name: 'Perl',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'perl',
    activeProjectCount: 123,
  };
}

export function javaSkill(): Skill {
  return {
    id: 7,
    name: 'Java',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'java',
    activeProjectCount: 92,
  };
}

export function javaScriptSkill(): Skill {
  return {
    id: 9,
    name: 'JavaScript',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'javascript',
    activeProjectCount: 27,
  };
}

export function adobeFlashSkill(): Skill {
  return {
    id: 12,
    name: 'Adobe Flash',
    category: {
      id: 3,
      name: 'Design, Media & Architecture',
    },
    local: false,
    seoUrl: 'flash',
    activeProjectCount: 2,
  };
}

export function pythonSkill(): Skill {
  return {
    id: 13,
    name: 'Python',
    category: { id: 1, name: 'Websites, IT & Software' },
    local: false,
    seoUrl: 'python',
    activeProjectCount: 6,
  };
}

export function websiteDesignSkill(): Skill {
  return {
    id: 17,
    name: 'Website Design',
    category: { id: 3, name: 'Design, Media & Architecture' },
    local: false,
    seoUrl: 'website-design',
    activeProjectCount: 619,
  };
}

export function graphicDesignSkill(): Skill {
  return {
    id: 20,
    name: 'Graphic Design',
    category: { id: 3, name: 'Design, Media & Architecture' },
    local: false,
    seoUrl: 'graphic-design',
    activeProjectCount: 290,
  };
}

export function copywritingSkill(): Skill {
  return {
    id: 21,
    name: 'Copywriting',
    category: {
      id: 2,
      name: 'Writing & Content',
    },
    local: false,
    seoUrl: 'copywriting',
    activeProjectCount: 17,
  };
}

export function translationSkill(): Skill {
  return {
    id: 22,
    name: 'Translation',
    category: { id: 2, name: 'Writing & Content' },
    local: false,
    seoUrl: 'translation',
    activeProjectCount: 13,
  };
}

export function photographySkill(): Skill {
  return {
    id: 27,
    name: 'Photography',
    category: { id: 3, name: 'Design, Media & Architecture' },
    local: true,
    seoUrl: 'photography',
    activeProjectCount: 63,
  };
}

export function linuxSkill(): Skill {
  return {
    id: 31,
    name: 'Linux',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'linux',
    activeProjectCount: 35,
  };
}

export function logoDesignSkill(): Skill {
  return {
    id: 32,
    name: 'Logo Design',
    category: { id: 3, name: 'Design, Media & Architecture' },
    local: false,
    seoUrl: 'logo-design',
    activeProjectCount: 102,
  };
}

export function dataProcessingSkill(): Skill {
  return {
    id: 36,
    name: 'Data Processing',
    category: { id: 4, name: 'Data Entry & Admin' },
    local: false,
    seoUrl: 'data-processing',
    activeProjectCount: 6,
  };
}

export function dataEntrySkill(): Skill {
  return {
    id: 39,
    name: 'Data Entry',
    category: { id: 4, name: 'Data Entry & Admin' },
    local: false,
    seoUrl: 'data-entry',
    activeProjectCount: 45,
  };
}

export function accountingSkill(): Skill {
  return {
    id: 53,
    name: 'Accounting',
    category: {
      id: 7,
      name: 'Business, Accounting, Human Resources & Legal',
    },
    local: false,
    seoUrl: 'accounting',
    activeProjectCount: 8,
  };
}

export function photoshopSkill(): Skill {
  return {
    id: 57,
    name: 'Photoshop',
    category: {
      id: 3,
      name: 'Design, Media & Architecture',
    },
    local: false,
    seoUrl: 'photoshop',
    activeProjectCount: 57,
  };
}

export function androidSkill(): Skill {
  return {
    id: 59,
    name: 'Android',
    category: { id: 9, name: 'Mobile Phones & Computing' },
    local: false,
    seoUrl: 'android',
    activeProjectCount: 1201,
  };
}

export function mobileAppDevelopmentSkill(): Skill {
  return {
    id: 44,
    name: 'Mobile App Development',
    category: { id: 9, name: 'Mobile Phones & Computing' },
    local: false,
    seoUrl: 'mobile-app-development',
    activeProjectCount: 45,
  };
}

export function articleWritingSkill(): Skill {
  return {
    id: 174,
    name: 'Article Writing',
    category: { id: 2, name: 'Writing & Content' },
    local: false,
    seoUrl: 'article-writing',
    activeProjectCount: 45,
  };
}

export function deliverySkill(): Skill {
  return {
    id: 632,
    name: 'Delivery',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'delivery',
    activeProjectCount: 15,
  };
}

export function freightSkill(): Skill {
  return {
    id: 1517,
    name: 'International Shipping',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'international-shipping',
    activeProjectCount: 15,
  };
}

export function palletsSkill(): Skill {
  return {
    id: 1518,
    name: 'Pallets',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'pallets',
    activeProjectCount: 15,
  };
}

export function skillQuestionDriveable(): SkillQuestion {
  return {
    id: 15,
    internalName: 'isDriveable',
    displayName: 'Is it driveable?',
    contextType: SkillQuestionContextTypeApi.DELIVERY_ITEM,
  };
}

export function skillAnswerDriveable(): SkillAnswer {
  return {
    id: generateId(),
    questionId: 15,
    content: 'Driveable',
    contextId: generateId(),
  };
}

export function skillAnswerNotDriveable(): SkillAnswer {
  return {
    id: generateId(),
    questionId: 15,
    content: 'Not driveable',
    contextId: generateId(),
  };
}

export function skillQuestionEmpty(): SkillQuestion {
  return {
    id: 16,
    internalName: 'isEmpty',
    displayName: 'Is it empty?',
    contextType: SkillQuestionContextTypeApi.DELIVERY_ITEM,
  };
}

export function skillQuestionRegistered(): SkillQuestion {
  return {
    id: 17,
    internalName: 'isRegistered',
    displayName: 'Is it registered?',
    contextType: SkillQuestionContextTypeApi.DELIVERY_ITEM,
  };
}

export function skillAnswerRegistered(): SkillAnswer {
  return {
    id: generateId(),
    questionId: 17,
    content: 'Registered',
    contextId: generateId(),
  };
}

export function skillAnswerNotRegistered(): SkillAnswer {
  return {
    id: generateId(),
    questionId: 17,
    content: 'Not registered',
    contextId: generateId(),
  };
}

export function skillQuestionPackageMethod(): SkillQuestion {
  return {
    id: 38,
    internalName: 'packageMethod',
    displayName: 'How is the hay packaged or contained?',
    contextType: SkillQuestionContextTypeApi.DELIVERY_ITEM,
  };
}

export function skillQuestionRequiresProtection(): SkillQuestion {
  return {
    id: 39,
    internalName: 'requiresMoistureAndContaminationProtection',
    displayName:
      'Does the hay need to be protected from moisture or contamination during transit?',
    contextType: SkillQuestionContextTypeApi.DELIVERY_ITEM,
  };
}

export function skillQuestionHayOrGrassType(): SkillQuestion {
  return {
    id: 40,
    internalName: 'HayOrGrassType',
    displayName: 'What type of hay / grass is it?',
    contextType: SkillQuestionContextTypeApi.DELIVERY_ITEM,
  };
}

export function carSkill(): Skill {
  return {
    id: 1519,
    name: 'Car',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'car',
    activeProjectCount: 15,
    questions: [
      skillQuestionDriveable(),
      skillQuestionEmpty(),
      skillQuestionRegistered(),
    ],
  };
}

export function haySkill(): Skill {
  return {
    id: 1541,
    name: 'Hay',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'hay',
    activeProjectCount: 15,
    questions: [
      skillQuestionPackageMethod(),
      skillQuestionRequiresProtection(),
      skillQuestionHayOrGrassType(),
    ],
  };
}

export function machineryMobile(): Skill {
  return {
    id: 1520,
    name: 'Machinery (Mobile)',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'machinery-mobile',
    activeProjectCount: 15,
  };
}

export function machinerySkill(): Skill {
  return {
    id: 1520,
    name: 'Machinery',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'machinery',
    activeProjectCount: 15,
  };
}

export function generalFullLoadSkill(): Skill {
  return {
    id: 1525,
    name: 'General Full Load',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'general-full-load',
    activeProjectCount: 15,
  };
}

export function BoatSkill(): Skill {
  return {
    id: 1526,
    name: 'Boat',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'boat',
    activeProjectCount: 15,
  };
}

export function LivestockSkill(): Skill {
  return {
    id: 1527,
    name: 'Livestock',
    category: {
      id: 100,
      name: 'Freight, Shipping & Transportation',
    },
    local: true,
    seoUrl: 'livestock',
    activeProjectCount: 15,
  };
}

export function otherSkill(): Skill {
  return {
    id: 35,
    name: 'Virtual Reality',
    category: {
      id: 99,
      name: 'Other',
    },
    local: false,
    seoUrl: 'Virtual-Reality',
    activeProjectCount: 23,
  };
}

export function cryptocurrencySkill(): Skill {
  return {
    id: 2658,
    name: 'Cryptocurrency',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'cryptocurrency',
    activeProjectCount: 13,
  };
}

export function heygenSkill(): Skill {
  return {
    id: 1578,
    name: 'HeyGen',
    category: {
      id: 1,
      name: 'Websites, IT & Software',
    },
    local: false,
    seoUrl: 'heygen',
    activeProjectCount: 0,
  };
}

export const defaultPredictedSkills = [
  phpSkill(),
  websiteDesignSkill(),
  translationSkill(),
  logoDesignSkill(),
  dataEntrySkill(),
] as const;
